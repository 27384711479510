import {
  AlignItems,
  Box,
  ColorPreset,
  Field,
  FormFieldStatus,
  Hint,
  Input,
  Label,
  Option,
  Text,
  TypePreset,
  Visibility,
  Select,
} from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";
import { getInputErrorStatus } from "src/utils/inputValidation";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";

const UNTIL_FURTHER_NOTICE = "until_further_notice";
const NUMBER_OF_PAY = "number_of_pay";

interface FormWithNumberOfPayments {
  timeframe?: string;
  count?: number;
}

export const NumberOfPaymentsField: React.FC<{ disabled?: boolean }> = ({
  disabled,
}) => {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext<FormWithNumberOfPayments>();
  const timeframe = watch("timeframe");
  const { i18n } = useLingui();

  return (
    <Box spaceAbove={2}>
      <Field>
        <Label htmlFor="timeframe">
          <Trans id="create-subscription.number-of-payment">
            How long do you want to take these payments for?
          </Trans>
        </Label>
        <Select
          id="timeframe"
          defaultValue={UNTIL_FURTHER_NOTICE}
          {...register("timeframe", {
            required: true,
          })}
          disabled={disabled}
        >
          <Option value={UNTIL_FURTHER_NOTICE}>
            {i18n._(
              t({
                id: "customer.until-further-notice",
                message: "Until further notice",
              })
            )}
          </Option>
          <Option value={NUMBER_OF_PAY}>
            {i18n._(
              t({
                id: "customer.specific-number-of-payments",
                message: "For a specific number of payments",
              })
            )}
          </Option>
        </Select>
      </Field>
      {timeframe === NUMBER_OF_PAY && (
        <Box
          spaceAbove={1}
          layout="flex"
          borderColor={ColorPreset.BorderOnLight_03}
          borderWidth={1}
          bg={ColorPreset.BackgroundLight_03}
          borderRadius={1}
        >
          <Box layout="flex" width="90%" alignItems={AlignItems.Center}>
            <Box
              width="100%"
              spaceBefore={1.5}
              spaceAbove={1.5}
              spaceBelow={1.5}
              spaceAfter={1.5}
            >
              <Field>
                <Visibility visible="none">
                  <Label htmlFor="count">Number of payments</Label>
                </Visibility>
                <Input
                  type="number"
                  id="count"
                  status={getInputErrorStatus(!!errors.count)}
                  {...register("count", {
                    required: true,
                    min: 1,
                  })}
                />
                {errors.count && (
                  <Hint status={FormFieldStatus.Danger}>
                    {errors.count.message || errors.count.types?.message || (
                      <Trans id="customer.count-is-required">
                        Count is required, Must not be less than 1
                      </Trans>
                    )}
                  </Hint>
                )}
              </Field>
            </Box>
            <Text spaceAbove={1} preset={TypePreset.Body_01}>
              <Trans id="customer.payments">Payments</Trans>
            </Text>
          </Box>
        </Box>
      )}
    </Box>
  );
};
