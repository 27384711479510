import { Field, Input, Label, VisuallyHidden } from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { error } from "src/components/routes/SetupPayments/common/fields/helper";
import { getInputErrorStatus } from "src/utils/inputValidation";

export interface FormWithCustomIntervalAmount {
  interval?: number;
}

export const fieldName = "interval";
const MIN_INTERVAL_AMOUNT = 1;
const MAX_INTERVAL_AMOUNT = 60;
export const defaultValue = MIN_INTERVAL_AMOUNT;

export const CustomIntervalAmountField: React.FC = () => {
  const { i18n } = useLingui();
  const {
    register,
    formState: { errors },
  } = useFormContext<FormWithCustomIntervalAmount>();
  const errorMessage = i18n._(
    t({
      id: "plan-create.error-interval-required",
      message:
        "Interval is required, Must not be less than 1 or greater than 60",
    })
  );

  return (
    <Field>
      <VisuallyHidden>
        <Label htmlFor={fieldName}>Interval count</Label>
      </VisuallyHidden>
      <Input
        id={fieldName}
        type="number"
        defaultValue={defaultValue}
        {...register(fieldName, {
          required: errorMessage,
          min: { value: MIN_INTERVAL_AMOUNT, message: errorMessage },
          max: { value: MAX_INTERVAL_AMOUNT, message: errorMessage },
          valueAsNumber: true,
        })}
        status={getInputErrorStatus(!!errors[fieldName])}
      />
      {error(errors, fieldName)}
    </Field>
  );
};
