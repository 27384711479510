import { ReactNode, useState, createRef, Fragment } from "react";
import {
  AlignItems,
  Box,
  Color,
  ColorPreset,
  ColorProp,
  FontWeight,
  LI,
  PlainButton,
  ResponsiveValue,
  Separator,
  TypePreset,
  UL,
  useTheme,
} from "@gocardless/flux-react";

export interface SegmentedButtonItem {
  text: ReactNode;
  key: string;
}

export enum SegmentedButtonsSize {
  Sm = "sm",
  Md = "md",
  Lg = "lg",
}

export interface SegmentedButtonsProps {
  items: SegmentedButtonItem[];
  onSelected?: (key: string) => void;
  initialSelected?: string;
  size?: ResponsiveValue<SegmentedButtonsSize>;
}

export const SegmentedButtons: React.FC<SegmentedButtonsProps> = ({
  items,
  onSelected,
  initialSelected,
  size = SegmentedButtonsSize.Md,
}) => {
  const { theme } = useTheme();
  const [selected, setSelected] = useState<string>(
    initialSelected || items[0]?.key || ""
  );
  const buttonRefs = items.map(() => createRef<HTMLButtonElement>());

  const buttonPadding =
    size === SegmentedButtonsSize.Lg
      ? `${theme.spacing(1)} ${theme.spacing(3)}`
      : size === SegmentedButtonsSize.Md
        ? `${theme.spacing(0.75)} ${theme.spacing(2)}`
        : `${theme.spacing(0.5)} ${theme.spacing(1.5)}`;

  const typePreset =
    size === SegmentedButtonsSize.Lg
      ? TypePreset.Body_02
      : size === SegmentedButtonsSize.Md
        ? TypePreset.Body_02
        : TypePreset.Body_01;

  const separatorVerticalPadding =
    size === SegmentedButtonsSize.Lg
      ? theme.spacing(1)
      : size === SegmentedButtonsSize.Md
        ? theme.spacing(0.75)
        : theme.spacing(0.5);

  const onClick = (key: string, index: number) => {
    setSelected(key);
    onSelected && onSelected(key);
    scrollIntoView(index);
  };

  const scrollIntoView = (index: number) => {
    buttonRefs[index]?.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  };

  const getSeparatorColor = (index: number): ColorProp => {
    const selectedIndex = items.findIndex(({ key }) => key === selected);
    // This shouldn't happen but if it does, handle the issue consistently.
    if (selectedIndex === -1) return Color.Transparent;

    // The separators should show if the currently selected button is not
    // adjacent to it in the component. We need to do a little maths here to
    // work out whether this is true, because a separator comes after each
    // button in the items array (except for the last one).
    const isShowing = selectedIndex - index <= -1 || selectedIndex - index > 1;

    return isShowing ? ColorPreset.BorderOnLight_03 : Color.Transparent;
  };

  return (
    <Box
      layout="inline-block"
      borderRadius={2}
      css={{
        backgroundColor: "rgba(212, 203, 190, 0.38)",
      }}
      maxWidth="100%"
    >
      <Box css={{ borderRadius: theme.spacing(2), overflowX: "auto" }}>
        <UL
          decoration="none"
          marker="none"
          css={{ display: "flex" }}
          gutterH={0}
        >
          {items.map(({ text, key }, index) => (
            <Fragment key={key}>
              <LI css={{ display: "flex" }}>
                <PlainButton
                  ref={buttonRefs[index]}
                  css={{
                    flex: 1,
                    flexShrink: 0,
                    backgroundColor:
                      key === selected
                        ? theme.color(Color.White)
                        : "transparent",
                    padding: buttonPadding,
                    borderRadius: theme.spacing(2),
                    borderWidth: "2px",
                    borderStyle: "solid",
                    borderColor:
                      key === selected
                        ? theme.color(ColorPreset.BorderOnLight_04)
                        : "transparent",
                    transition: "background-color 150ms ease-out",
                    "&:hover": {
                      backgroundColor:
                        key === selected
                          ? theme.color(Color.White)
                          : "rgba(164, 158, 149, 0.21)",
                      textDecoration:
                        key === selected ? "initial" : "underline",
                    },
                    "&:focus-visible": {
                      outline: "none",
                      borderColor: theme.color(Color.Greystone_1400),
                    },
                  }}
                  onClick={() => onClick(key, index)}
                  onFocus={() => scrollIntoView(index)}
                  color={ColorPreset.TextOnLight_01}
                  preset={typePreset}
                  weight={FontWeight.SemiBold}
                >
                  {text}
                </PlainButton>
              </LI>
              {index !== items.length - 1 ? (
                <Box
                  // Non-standard left/right spacing as we need to have a very
                  // small 2px space here.
                  css={{ padding: `${separatorVerticalPadding} 2px` }}
                  layout="flex"
                  alignItems={AlignItems.Center}
                >
                  <Separator
                    direction="inline-vertical"
                    color={getSeparatorColor(index)}
                    css={{
                      transition: "background-color 150ms ease-out",
                    }}
                    length="100%"
                  />
                </Box>
              ) : null}
            </Fragment>
          ))}
        </UL>
      </Box>
    </Box>
  );
};
