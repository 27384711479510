import { IntervalUnit } from "@gocardless/api/dashboard/types";

const MONTH_COUNT = 14;
export const CUSTOM_INTERVAL = "Custom";

export const DAY_OF_MONTHS = [
  "1st",
  "2nd",
  "3rd",
  "4th",
  "5th",
  "6th",
  "7th",
  "8th",
  "9th",
  "10th",
  "11th",
  "12th",
  "13th",
  "14th",
  "15th",
  "16th",
  "17th",
  "18th",
  "19th",
  "20th",
  "21st",
  "22nd",
  "23rd",
  "24th",
  "25th",
  "26th",
  "27th",
  "28th",
];

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const generateMonthYearOptions = () => {
  const monthYear = [];
  let year = new Date().getFullYear();
  let month = new Date().getMonth();
  for (let i = 0; i < MONTH_COUNT; i++) {
    monthYear.push({
      id: (MONTHS[month] as string) + year,
      month: MONTHS[month] as string,
      year: year,
    });
    month = month + 1;
    if (!MONTHS[month]) {
      year = year + 1;
      month = 0;
    }
  }
  return monthYear;
};

export const getIntervalUnit = (
  intervalUnit: IntervalUnit | typeof CUSTOM_INTERVAL,
  customIntervalUnit?: IntervalUnit
): IntervalUnit =>
  intervalUnit !== CUSTOM_INTERVAL
    ? intervalUnit
    : customIntervalUnit || IntervalUnit.Weekly;
