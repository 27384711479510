import {
  AlignItems,
  Box,
  Field,
  FormFieldStatus,
  Hint,
  Input,
  Label,
  Tag,
  TagColor,
  TagSize,
  TagVariant,
  Text,
  Tooltip,
} from "@gocardless/flux-react";
import { t, Trans } from "@lingui/macro";
import { getInputErrorStatus, VALID_URL } from "src/utils/inputValidation";
import { FieldErrors, FieldValues, useFormContext } from "react-hook-form";
import { useLingui } from "@lingui/react";

export const error = <T extends FieldValues>(
  errors: FieldErrors<T>,
  name: keyof typeof errors
) =>
  errors[name] ? (
    <Hint status={FormFieldStatus.Danger}>
      {errors[name]?.message as string | undefined}
    </Hint>
  ) : null;

export const RedirectURLField: React.FC = () => {
  const fieldName = "redirect_url";
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { i18n } = useLingui();

  return (
    <Field>
      <Label htmlFor={fieldName}>
        <Box layout="flex" alignItems={AlignItems.Center}>
          <Text>
            <Trans id="plan-create.redirect-url">Redirect URL</Trans>{" "}
            <Tag
              size={TagSize.Sm}
              color={TagColor.Neutral}
              variant={TagVariant.Tinted}
            >
              <Trans id="plan-create.optional">Optional</Trans>
            </Tag>
          </Text>
          <Tooltip
            tooltipId="description-tooltip"
            placement="right"
            message={i18n._(
              t({
                id: "subscription-create.redirect-tooltip",
                message:
                  "If specified, we will redirect your customers here once they've signed up to your Subscription template.",
              })
            )}
          >
            <></>
          </Tooltip>
        </Box>
      </Label>
      <Input
        data-testid="redirect-url"
        type="text"
        id={fieldName}
        placeholder="https://yourwebsite.com"
        {...register(fieldName, {
          pattern: VALID_URL,
        })}
        status={getInputErrorStatus(!!errors[fieldName])}
      />
      {errors[fieldName] ? (
        <Hint status={FormFieldStatus.Danger}>
          <Trans id="plan-create.error-enter-valid-url">
            Please enter a valid URL
          </Trans>
        </Hint>
      ) : null}
    </Field>
  );
};
