import { Hint, FormFieldStatus } from "@gocardless/flux-react";
import { FieldErrors, FieldValues } from "react-hook-form";

export const error = (
  errors: FieldErrors<FieldValues>,
  name: keyof typeof errors
) =>
  errors[name] ? (
    <Hint status={FormFieldStatus.Danger}>
      {errors[name]?.message?.toString()}
    </Hint>
  ) : null;
