import {
  Field,
  Label,
  SelectInput,
  DefaultListItemProps,
} from "@gocardless/flux-react";
import { useFormContext, useWatch } from "react-hook-form";
import { getPlanDescription } from "src/utils/planDescription";
import { PlanResource } from "@gocardless/api/dashboard/types";
import { Trans, t } from "@lingui/macro";
import { getInputErrorStatus } from "src/utils/inputValidation";
import { useMemo, useState } from "react";
import { useLingui } from "@lingui/react";
import debounce from "lodash/debounce";

interface FormWithPlan {
  planId: string;
}

export type PlanList = [PlanResource, ...PlanResource[]];

interface PlanFieldProps {
  planList: PlanList;
  isRestricted: boolean;
}

export const PlanField: React.FC<PlanFieldProps> = ({
  planList,
  isRestricted,
}) => {
  const { i18n } = useLingui();
  const [searchInput, setSearchInput] = useState("");
  const {
    setValue,
    formState: { errors },
  } = useFormContext<FormWithPlan>();

  const value = useWatch({ name: "planId" });

  const unFilteredItems = planList.map((plan) => ({
    value: plan.id ?? "",
    label: `${plan.name} (${getPlanDescription(plan)})`,
  }));

  const items = useMemo(() => {
    if (!searchInput) {
      return unFilteredItems;
    }
    return unFilteredItems?.filter(
      ({ label, value: itemValue }: DefaultListItemProps) =>
        label.toLowerCase().includes(searchInput?.toLowerCase()) ||
        `${itemValue}`.toLowerCase().includes(searchInput?.toLowerCase())
    );
  }, [searchInput, unFilteredItems]);

  const onFilter = debounce((e) => {
    setSearchInput(e.target.value);
  }, 300);

  const selectedItem =
    unFilteredItems.find((item) => item.value === value) || null;

  const placeholderSearchText = i18n._(
    t({
      id: "seach-a-subscription-template",
      message: "Search for a subscription template",
    })
  );

  const placeholderTriggerText = i18n._(
    t({
      id: "choose-a-subscription-template",
      message: "Choose a Subscription template",
    })
  );

  const emptyResultMessage = i18n._(
    t({
      message: "That search returned no results",
      id: "search.index.returned_no_results",
    })
  );

  return (
    <Field>
      <Label htmlFor="planId">
        <Trans id="choose-a-subscription-template">
          Choose a Subscription template
        </Trans>
      </Label>

      <SelectInput<DefaultListItemProps>
        searchInputProps={{
          placeholder: placeholderSearchText,
          onChange: onFilter,
        }}
        virtualize={items.length > 100}
        onClose={() => setSearchInput("")}
        placeholder={placeholderTriggerText}
        maxHeight={300}
        emptyMessage={emptyResultMessage}
        items={items}
        value={selectedItem}
        onChange={(selected) => {
          setValue("planId", `${selected?.value ?? ""}`);
        }}
        status={getInputErrorStatus(!!errors.planId)}
        disabled={isRestricted}
        id="planId"
      />
    </Field>
  );
};
