import { TwoPanelLayout } from "src/components/routes/RequestPayment/common/components/TwoPanelLayout/TwoPanelLayout";
import {
  Box,
  ColorPreset,
  FontWeight,
  H1,
  P,
  Text,
  TypePreset,
} from "@gocardless/flux-react";
import { SegmentedButtons } from "src/components/ui/segmented-buttons/SegmentedButtons";
import { useRouter } from "next/router";
import { useCustomerSelf } from "@gocardless/api/dashboard/customer";
import { routerReplace, Route } from "src/common/routing";
import { useLingui } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { ExistingCustomerCreateInstructions } from "src/components/routes/RequestPayment/RecurringPayment/ExistingCustomerCreate/ExistingCustomerCreateInstructions";
import { CreateNewSubscription } from "src/components/routes/RequestPayment/RecurringPayment/ExistingCustomerCreate/CreateNewSubscription";

import { AddToPlan } from "./AddToPlanForm";

export const ADD_TO_PLAN_TAB = "add_to_subscription_template";
export const CREATE_SUBSCRIPTION_TAB = "create_subscription";

const LeftPanel: React.FC = () => {
  const { i18n } = useLingui();

  const segmentedButtonItems = [
    {
      key: ADD_TO_PLAN_TAB,
      text: i18n._(t({ message: "Add to existing template" })),
    },
    {
      key: CREATE_SUBSCRIPTION_TAB,
      text: i18n._(t({ message: "Create new subscription" })),
    },
  ];

  const router = useRouter();
  const tabParam =
    (router.query.tab as string) || segmentedButtonItems?.[0]?.key;

  const customerId = router.query.customer_id as string;

  const { data: customerResponse } = useCustomerSelf(customerId);
  const customer = customerResponse?.customers;
  const customerName = customer?.name ?? "";

  return (
    <Box spaceBelow={2}>
      <H1 preset={TypePreset.Heading_07} spaceBelow={1}>
        <Trans id="existing-customer-create.header-title">
          Subscription for{" "}
          <Text weight={FontWeight.Normal}>{customerName}</Text>
        </Trans>
      </H1>
      <P color={ColorPreset.TextOnLight_02} spaceBelow={2}>
        <Trans id="existing-customer-create.header-description">
          Choose the type of Subscription you’d like to create and enter the
          payment details to complete the request.
        </Trans>
      </P>
      <SegmentedButtons
        onSelected={(tab) =>
          routerReplace({
            route: Route.RecurringPaymentCreate,
            queryParams: {
              customer_id: customerId,
              tab,
            },
          })
        }
        initialSelected={tabParam}
        items={segmentedButtonItems}
      />
      {tabParam === ADD_TO_PLAN_TAB ? (
        <AddToPlan customer={customer} />
      ) : (
        <CreateNewSubscription customerId={customerId} />
      )}
    </Box>
  );
};

export const ExistingCustomerCreate: React.FC = () => (
  <TwoPanelLayout
    leftPanel={<LeftPanel />}
    rightPanel={<ExistingCustomerCreateInstructions />}
    centerRightPanelContent
    preventLeftPanelContentShrink
  />
);
