import {
  ColorPreset,
  H5,
  P,
  Space,
  Text,
  TypePreset,
} from "@gocardless/flux-react";
import { DateFormatter, parseDate } from "@internationalized/date";
import { Trans } from "@lingui/macro";
import { TakePaymentAsSoonAsPossibleField as CommonTakePaymentAsSoonAsPossibleField } from "src/components/form/TakePaymentAsSoonAsPossibleField";

interface TakePaymentAsSoonAsPossibleFieldProps {
  isRestricted?: boolean;
  firstAvailableDate: string;
  hideDescription?: boolean;
}

export const TakePaymentAsSoonAsPossibleField: React.FC<
  TakePaymentAsSoonAsPossibleFieldProps
> = ({ isRestricted, firstAvailableDate, hideDescription }) => (
  <>
    <H5>
      <Trans id="add-to-plan.when-to-collect-payment">
        When do you want to collect these payments?
      </Trans>
    </H5>
    {hideDescription ? null : (
      <>
        <Space v={0.75} />
        <P color={ColorPreset.TextOnLight_02}>
          <Trans id="add-to-plan.when-to-collect-payment-description">
            Your Subscription payments will be collected automatically using
            Direct Debit. Payment processing takes 5 business days.
          </Trans>
        </P>
      </>
    )}
    <CommonTakePaymentAsSoonAsPossibleField
      isRestricted={isRestricted}
      asapLabelContent={
        <Trans id="add-to-plan.collect-as-soon-as-possible.title">
          Collect as soon as possible
        </Trans>
      }
      renderAsapLabelDescription={() => (
        <Trans>
          <P
            weight={400}
            color={ColorPreset.TextOnLight_02}
            preset={TypePreset.Body_01}
          >
            Estimated date to receive payment -&nbsp;
            <Text
              weight={600}
              color={ColorPreset.TextOnLight_02}
              preset={TypePreset.Body_01}
            >
              {firstAvailableDate &&
                new DateFormatter("en-GB", {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                }).format(
                  parseDate(firstAvailableDate).toDate("Europe/London")
                )}
            </Text>
            &nbsp;(future payments will be taken on the same day)
          </P>
        </Trans>
      )}
      specificDayLabelContent={
        <Trans id="add-to-plan.collect-on-specific-day">
          Collect payments on a specific day
        </Trans>
      }
      specificDayLabelDescription={
        <P
          weight={400}
          color={ColorPreset.TextOnLight_02}
          preset={TypePreset.Body_01}
        >
          <Trans>
            You’ll receive payment on your specified day (future payments will
            be taken on the same day)
          </Trans>
        </P>
      }
    />
  </>
);
