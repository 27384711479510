import { IntervalUnit } from "@gocardless/api/dashboard/types";
import _ from "lodash";
import moment from "moment";

import { intervalUnitToMomentIntervalUnit } from "./intervalStore";
import { throwInvalidIntervalUnit } from "./throwInvalidIntervalUnit";

const addDate = ({
  startDate,
  intervalUnit,
}: {
  startDate: moment.Moment | string;
  intervalUnit: IntervalUnit;
}): moment.Moment =>
  moment(startDate).add(
    1,
    _.get(
      intervalUnitToMomentIntervalUnit,
      intervalUnit
    ) as moment.unitOfTime.DurationConstructor
  );

const iterativeDateSetBuilder = ({
  dateSet,
  startDate,
  intervalUnit,
  endDate,
  lastDay,
}: {
  dateSet: moment.Moment[];
  startDate: moment.Moment | string;
  intervalUnit: IntervalUnit;
  endDate: moment.Moment | string;
  lastDay?: boolean;
}): moment.Moment[] => {
  const newDate = addDate({ startDate, intervalUnit });
  const isAfterEndDate = newDate.isAfter(moment(endDate));
  if (isAfterEndDate) return dateSet;

  const newDateSet = _.concat(
    dateSet,
    _.isUndefined(lastDay) ? newDate : newDate.endOf("month")
  );

  return iterativeDateSetBuilder({
    dateSet: newDateSet,
    startDate: newDate,
    intervalUnit,
    endDate,
    lastDay,
  });
};

export const dateSetBuilder = ({
  startDate,
  intervalUnit,
  endDate,
  lastDay,
}: {
  startDate: moment.Moment | string;
  intervalUnit: IntervalUnit;
  endDate: moment.Moment | string;
  lastDay?: boolean;
}): moment.Moment[] => {
  throwInvalidIntervalUnit(intervalUnit);

  const initialDate = moment(startDate);
  if (!_.isUndefined(lastDay)) {
    initialDate.endOf("month");
  }
  const initialDateSet = [initialDate];

  return iterativeDateSetBuilder({
    dateSet: initialDateSet,
    startDate,
    intervalUnit,
    endDate,
    lastDay,
  });
};
