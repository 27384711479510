import {
  ColorPreset,
  Field,
  FormFieldStatus,
  Hint,
  Input,
  Label,
  Space,
  Text,
  TypePreset,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { ReactNode } from "react";
import { useFormContext } from "react-hook-form";
import { Currency, currencyToSymbol } from "src/common/currencies";
import { VALID_AMOUNT, getInputErrorStatus } from "src/utils/inputValidation";

export interface FormWithAmount {
  amount: string;
}

interface AmountFieldProps {
  selectedCurrency: Currency;
  label?: ReactNode;
  disabled?: boolean;
}

export const fieldName = "amount";

export const AmountField: React.FC<AmountFieldProps> = ({
  selectedCurrency,
  label = (
    <Trans id="request-payment.one-off-payment-form-amount-label">Amount</Trans>
  ),
  disabled = false,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<FormWithAmount>();

  return (
    <Field>
      <Label htmlFor={fieldName}>{label}</Label>
      <Input
        id={fieldName}
        type="text"
        inputMode="decimal"
        status={getInputErrorStatus(!!errors.amount)}
        min={0.01}
        step={0.01}
        placeholder="0.00"
        disabled={disabled}
        {...register(fieldName, {
          required: true,
          pattern: VALID_AMOUNT,
        })}
        leftAccessory={
          <>
            <Space layout="inline" h={1} />
            <Text
              preset={TypePreset.Body_02}
              color={ColorPreset.TextOnLight_02}
            >
              {currencyToSymbol[selectedCurrency]}
            </Text>
          </>
        }
      />
      {errors.amount ? (
        <Hint status={FormFieldStatus.Danger}>
          {errors.amount.message ||
            errors.amount.types?.message ||
            "Please enter an amount"}
        </Hint>
      ) : null}
    </Field>
  );
};
