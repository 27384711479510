import {
  AlignItems,
  Box,
  ColorPreset,
  ColorScheme,
  JustifyContent,
  P,
  Tag,
  TagColor,
  TagSize,
  TagVariant,
  Text,
  TypePreset,
  Visibility,
} from "@gocardless/flux-react";
import Image from "next/image";
import { Trans } from "@lingui/macro";

export interface TipProps {
  imagePath?: string | null;
  header: string;
  details: React.ReactNode;
  showTipTag?: boolean;
}

const TipImage: React.FC<{ imagePath: string }> = ({ imagePath }) => (
  <Box
    gutterH={1}
    gutterV={1}
    justifyContent={[JustifyContent.FlexStart, null, JustifyContent.Center]}
    alignItems={AlignItems.Center}
    layout="flex"
  >
    <Image src={imagePath} alt="" width="160" height="160" />
  </Box>
);

export const Tip: React.FC<TipProps> = ({
  header,
  details,
  imagePath,
  showTipTag,
}) => (
  <Box
    spaceAbove={1}
    layout="flex"
    flexDirection={["column", null, "row"]}
    gutterV={1}
    gutterH={1}
    bg={ColorPreset.BackgroundLight_01}
    borderRadius={1}
    css={{ boxShadow: "0px 2px 12px rgba(30, 26, 20, 0.1)" }}
  >
    {imagePath ? (
      <Visibility visible={["block", null, "none"]}>
        <TipImage imagePath={imagePath} />
      </Visibility>
    ) : null}
    <Box alignSelf={AlignItems.Center} gutterH={1} gutterV={1}>
      {showTipTag ? (
        <Box spaceBelow={1}>
          <Tag
            variant={TagVariant.Tinted}
            size={TagSize.Md}
            colorScheme={ColorScheme.OnLight}
            color={TagColor.Success}
          >
            <Text textTransform="uppercase">
              <Trans>Tip</Trans>
            </Text>
          </Tag>
        </Box>
      ) : null}
      <P
        spaceBelow={1}
        preset={TypePreset.Heading_03}
        color={ColorPreset.TextOnLight_01}
      >
        {header}
      </P>
      <P preset={TypePreset.Body_01} color={ColorPreset.TextOnLight_02}>
        {details}
      </P>
    </Box>
    {imagePath ? (
      <Visibility visible={["none", null, "block"]}>
        <TipImage imagePath={imagePath} />
      </Visibility>
    ) : null}
  </Box>
);
