import { MandateResource, PlanResource } from "@gocardless/api/dashboard/types";
import _ from "lodash";
import moment from "moment";
import { isoDateFormat } from "src/common/planHelpers/dateConstants";
import { DateSetItem } from "src/common/planHelpers/dateHelperTypes";
import { dateSetBuilder } from "src/common/planHelpers/dateSetBuilder";
import { dateSetPresenter } from "src/common/planHelpers/dateSetPresenter";
import {
  intervalUnitToMomentIntervalUnit,
  LAST_WORKING_DAY,
  MONTHLY_INTERVAL_UNIT,
  YEARLY_INTERVAL_UNIT,
} from "src/common/planHelpers/intervalStore";

export const isPlanMonthlyOrYearly = (plan: PlanResource): boolean => {
  if (_.isUndefined(plan)) {
    throw new Error("Undefined shouldn't be passed to isPlanMonthlyOrYearly");
  }

  return _.some([MONTHLY_INTERVAL_UNIT, YEARLY_INTERVAL_UNIT], (value) =>
    _.eq(_.get(plan, "interval_unit"), value)
  );
};

export const isSetPlan = (plan?: PlanResource): boolean => {
  if (_.isUndefined(plan)) {
    throw new Error(
      "isSetPlan shouldn't be called with an undefined parameter"
    );
  }

  const intervalUnitDependencies = {
    weekly: ["day_of_week"],
    monthly: ["day_of_month"],
    yearly: ["day_of_month", "month"],
  };
  const planIntervalUnitDependencies = _.get(
    intervalUnitDependencies,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    _.get(plan, "interval_unit")!
  );

  return _.every(
    planIntervalUnitDependencies,
    (dependency) => !_.isNull(_.get(plan, dependency, null))
  );
};

const validStartDate = (
  startDate: moment.Moment,
  plan: PlanResource,
  mandate: MandateResource
): moment.Moment => {
  const isValid = startDate.isSameOrAfter(
    moment(_.get(mandate, "next_possible_charge_date"))
  );
  if (isValid) return startDate;

  const incrementBy = _.get(
    intervalUnitToMomentIntervalUnit,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    _.get(plan, "interval_unit")!
  );

  return validStartDate(
    startDate.add(1, incrementBy as moment.unitOfTime.DurationConstructor),
    plan,
    mandate
  );
};

export const availableStartDates = (
  plan: PlanResource,
  mandate: MandateResource
): DateSetItem[] => {
  if (!isSetPlan(plan)) return [];

  const startDate = validStartDate(firstStartDate(plan), plan, mandate);
  const endDate = maxDate();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const intervalUnit = _.get(plan, "interval_unit")!;

  if (isPlanStartDateLastDayOfMonth(plan)) {
    const lastDayDateSet = dateSetBuilder({
      startDate: startDate.format(isoDateFormat),
      intervalUnit,
      endDate,
      lastDay: true,
    });

    return dateSetPresenter(lastDayDateSet, intervalUnit);
  }

  const dateSet = dateSetBuilder({
    startDate: startDate.format(isoDateFormat),
    intervalUnit,
    endDate,
  });
  return dateSetPresenter(dateSet, intervalUnit);
};

const isPlanStartDateLastDayOfMonth = (plan: PlanResource): boolean =>
  _.eq(_.get(plan, "day_of_month", null), LAST_WORKING_DAY);

const firstWeeklyStartDate = (plan: PlanResource): moment.Moment =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  moment().day(_.get(plan, "day_of_week")!);

const firstMonthlyStartDate = (plan: PlanResource): moment.Moment => {
  if (isPlanStartDateLastDayOfMonth(plan)) return moment().endOf("month");

  return moment().date(_.get(plan, "day_of_month") as number);
};

const firstYearlyStartDate = (plan: PlanResource): moment.Moment => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const dateHead = moment().month(_.get(plan, "month")!);
  if (isPlanStartDateLastDayOfMonth(plan)) return dateHead.endOf("month");

  return dateHead.date(_.get(plan, "day_of_month") as number);
};

const startDateFunctions = {
  weekly: firstWeeklyStartDate,
  monthly: firstMonthlyStartDate,
  yearly: firstYearlyStartDate,
};

const firstStartDate = (plan: PlanResource): moment.Moment =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  _.get(startDateFunctions, _.get(plan, "interval_unit")!)(plan);

// we hardcode the bacs dormancy period here for two reasons:
// 1. from a product perspective, we think it unlikely someone will need or want
// to subscribe someone to a plan > 13 months in the future
// 2. the backend currently hardcodes it, so exposing dates > 13 months away
// will return an API error
export const maxDate = (): string =>
  moment().add(13, "months").format(isoDateFormat);
