import { IntervalUnit } from "@gocardless/api/dashboard/types";
import _ from "lodash";
import moment from "moment";

import { isoDateFormat } from "./dateConstants";
import { DateSetItem } from "./dateHelperTypes";
import { MONTHLY_INTERVAL_UNIT, YEARLY_INTERVAL_UNIT } from "./intervalStore";
import { throwInvalidIntervalUnit } from "./throwInvalidIntervalUnit";

const INTERVAL_UNIT_DATE_FORMATS = {
  weekly: "dddd Do MMMM YYYY",
  monthly: "Do MMMM YYYY",
  yearly: "Do MMMM YYYY",
};

const label = (date: moment.Moment, intervalUnit: IntervalUnit): string => {
  if (
    _.eq(intervalUnit, MONTHLY_INTERVAL_UNIT) ||
    _.eq(intervalUnit, YEARLY_INTERVAL_UNIT)
  ) {
    const monthYear = date.format("MMMM YYYY");
    const lastDayLabel = `Last day of ${monthYear}`;
    const regularLabel = date.format(
      _.get(INTERVAL_UNIT_DATE_FORMATS, intervalUnit)
    );
    const endOfMonth = moment(_.cloneDeep(date)).endOf("month");
    const isEndOfMonth = date.isSame(endOfMonth, "day");

    return isEndOfMonth ? lastDayLabel : regularLabel;
  }

  return date.format(_.get(INTERVAL_UNIT_DATE_FORMATS, intervalUnit));
};

export const dateSetPresenter = (
  dateSet: moment.Moment[],
  intervalUnit: IntervalUnit
): DateSetItem[] => {
  throwInvalidIntervalUnit(intervalUnit);

  return _.map(dateSet, (date) => ({
    label: label(date, intervalUnit),
    value: date.format(isoDateFormat),
  }));
};
