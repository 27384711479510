import {
  Field,
  Option,
  VisuallyHidden,
  Label,
  Select,
} from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { IntervalUnit } from "@gocardless/api/dashboard/types";

export interface FormWithCustomIntervalUnit {
  custom_interval_unit?: IntervalUnit;
}

export const fieldName = "custom_interval_unit";
export const defaultValue = IntervalUnit.Weekly;

export const CustomIntervalUnitField: React.FC = () => {
  const { i18n } = useLingui();
  const { register } = useFormContext();

  return (
    <Field>
      <VisuallyHidden>
        <Label htmlFor="custom_interval_unit">
          <Trans id="plan-create.form-label-custom-interval-unit">
            Custom interval unit
          </Trans>
        </Label>
      </VisuallyHidden>
      <Select
        id={fieldName}
        defaultValue={defaultValue}
        {...register(fieldName, { required: true })}
      >
        <Option value={IntervalUnit.Weekly}>
          {i18n._(
            t({
              id: "plan-create.interval-weeks",
              message: `Weeks`,
            })
          )}
        </Option>
        <Option value={IntervalUnit.Monthly}>
          {i18n._(
            t({
              id: "plan-create.interval-months",
              message: "Months",
            })
          )}
        </Option>
      </Select>
    </Field>
  );
};
