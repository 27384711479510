import _ from "lodash";

import {
  MONTHLY_INTERVAL_UNIT,
  WEEKLY_INTERVAL_UNIT,
  YEARLY_INTERVAL_UNIT,
} from "./intervalStore";

const INTERVAL_UNITS = [
  WEEKLY_INTERVAL_UNIT,
  MONTHLY_INTERVAL_UNIT,
  YEARLY_INTERVAL_UNIT,
];

export const throwInvalidIntervalUnit = (intervalUnit: string) => {
  if (!_.includes(INTERVAL_UNITS, intervalUnit)) {
    throw new Error(
      `intervalUnit is ${intervalUnit} but must be one of ${_.join(
        INTERVAL_UNITS,
        ", "
      )}`
    );
  }
};
