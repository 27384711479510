import {
  Box,
  Field,
  JustifyContent,
  Label,
  Option,
  Text,
  TypePreset,
  Visibility,
  Select,
} from "@gocardless/flux-react";
import {
  DAY_OF_MONTHS,
  MONTHS,
  generateMonthYearOptions,
} from "src/components/form/helpers";
import { CalendarDate } from "@internationalized/date";
import { useFormContext } from "react-hook-form";

interface DayOfMonthFieldProps {
  minimumChargeDate: CalendarDate;
}

interface FormWithDayOfMonthField {
  day_of_month: number;
  month: string;
}

export const dayOfMonthFieldName = "day_of_month";
export const monthFieldName = "month";

export const DayOfMonthField: React.FC<DayOfMonthFieldProps> = ({
  minimumChargeDate,
}) => {
  const { register, setValue, watch } =
    useFormContext<FormWithDayOfMonthField>();
  const month = watch("month");
  const monthYearOptions = generateMonthYearOptions();

  return (
    <Box layout="flex" justifyContent={JustifyContent.SpaceBetween}>
      <Box width="50%">
        <Field>
          <Visibility visible="none">
            <Label htmlFor="day_of_month">Day of month</Label>
          </Visibility>
          <Select
            id="day_of_month"
            defaultValue={minimumChargeDate.day}
            {...register("day_of_month")}
          >
            {DAY_OF_MONTHS.map((day, index) => {
              const minimumChargeMonth =
                (MONTHS[minimumChargeDate.month - 1] || "") +
                minimumChargeDate.year;
              const minimumChargeDayOfMonth = minimumChargeDate.day;

              if (
                month === minimumChargeMonth &&
                index + 1 < minimumChargeDayOfMonth
              ) {
                return null;
              }
              return (
                <Option key={day} value={index + 1} data-testid={day}>
                  {day}
                </Option>
              );
            })}
            <Option key="lastDay" value={-1}>
              Last day
            </Option>
          </Select>
        </Field>
      </Box>
      <Box spaceAbove={1.5} spaceBefore={1.5} spaceAfter={1.5}>
        <Text preset={TypePreset.Body_01}>of</Text>
      </Box>

      <Box width="50%">
        <Field>
          <Visibility visible="none">
            <Label htmlFor="month">Month</Label>
          </Visibility>
          <Select
            id="month"
            {...register("month")}
            onChange={(event) => {
              const minimumChargeMonth =
                (MONTHS[minimumChargeDate.month - 1] || "") +
                minimumChargeDate.year;
              if (minimumChargeMonth === event.target.value) {
                setValue("day_of_month", minimumChargeDate.day);
              }
              setValue("month", event.target.value);
            }}
            defaultValue={monthYearOptions[0]?.id}
          >
            {monthYearOptions.map((key) => (
              <Option key={key.id} value={key.id}>
                {`${key.month} ${key.year}`}
              </Option>
            ))}
          </Select>
        </Field>
      </Box>
    </Box>
  );
};
