import {
  AlignItems,
  Box,
  ColorPreset,
  FieldSet,
  Label,
  Space,
  VisuallyHidden,
  XYGrid,
} from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";
import { Trans } from "@lingui/macro";

import { CUSTOM_INTERVAL } from "./IntervalUnitField";
import { CustomIntervalAmountField } from "./CustomIntervalAmountField";
import { CustomIntervalUnitField } from "./CustomIntervalUnitField";

export const CustomIntervalField: React.FC = () => {
  const { watch } = useFormContext();
  const intervalUnit = watch("interval_unit");

  return intervalUnit === CUSTOM_INTERVAL ? (
    <FieldSet>
      <VisuallyHidden>
        <Label htmlFor="custom_interval_unit">
          <Trans id="plan-create.form-label-custom-interval">
            Custom Interval
          </Trans>
        </Label>
      </VisuallyHidden>
      <Box
        gutterV={1}
        gutterH={1.5}
        borderColor={ColorPreset.BorderOnLight_03}
        bg={ColorPreset.BackgroundLight_03}
        borderRadius={1}
        borderWidth={1}
      >
        <XYGrid
          alignItems={AlignItems.Start}
          columnGap={1}
          templateColumns="auto 1fr 1fr"
        >
          <Box spaceAfter={0.5} spaceAbove={1.5}>
            <Trans id="plan-create.every">Every</Trans>
          </Box>
          <CustomIntervalAmountField />
          <CustomIntervalUnitField />
        </XYGrid>
        <Space v={0.5} />
      </Box>
    </FieldSet>
  ) : null;
};
