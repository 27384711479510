import {
  Banner,
  BannerStatus,
  BannerVariant,
  Box,
  Button,
  ButtonLayout,
  ButtonVariant,
  ColorPreset,
  FontWeight,
  Form,
  Glyph,
  Icon,
  JustifyContent,
  P,
  PlainButton,
  Space,
  Text,
  TypePreset,
  BannerLeftAccessoryType,
} from "@gocardless/flux-react";
import { useState } from "react";
import { Tip } from "src/components/ui/Tip/Tip";
import {
  PlanSubscribeRequestBody,
  useInfinitePlanList,
  usePlanSubscribe,
} from "@gocardless/api/dashboard/plan";
import {
  CustomerResource,
  PlanResource,
  PlansStatus,
  SchemeIdentifier,
} from "@gocardless/api/dashboard/types";
import { FormProvider, useForm } from "react-hook-form";
import { useMandateList } from "@gocardless/api/dashboard/mandate";
import {
  availableStartDates,
  isSetPlan,
} from "src/common/planHelpers/planHelper";
import { RestrictedDatePicker } from "src/components/ui/RestrictedDatePicker/RestrictedDatePicker";
import { useSchemeIdentifierList } from "@gocardless/api/dashboard/scheme-identifier";
import { useOrganisation } from "src/queries/organisation";
import { MandateResource } from "@gocardless/api/staff/types";
import _ from "lodash";
import { schemeToCurrency } from "src/common/scheme";
import { useToastNotification } from "src/hooks/useToastNotification";
import { getErrorNotification } from "src/hooks/useHandleFormError";
import { captureException } from "src/technical-integrations/sentry/sentry";
import { Route, routerPush } from "src/common/routing";
import { MobilePreview } from "src/components/routes/RequestPayment/common/components/TwoPanelLayout/TwoPanelLayout";
import { TrackingEvent } from "src/common/trackingEvents";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { useRouter } from "next/router";
import { CalendarDate, parseDate } from "@internationalized/date";
import { castDateToString, castStringToDate } from "src/common/date-helper";
import {
  PaymentsRequireApprovalNotice,
  PaymentType,
} from "src/components/ui/PaymentsRequireApprovalNotice/PaymentsRequireApprovalNotice";
import { useLingui } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { PlanField, PlanList } from "src/components/form/PlanField";
import { StartDatePickerField } from "src/components/routes/RequestPayment/RecurringPayment/ExistingCustomerCreate/FormFields/StartDatePickerField";
import { MandateField, MandateList } from "src/components/form/MandateField";
import { useIsRestricted } from "src/common/hooks/useIsRestricted";
import { VerifyYourAccountError } from "src/components/routes/Customers/CustomerDetail/common/VerifyYourAccountError";
import { useVerificationStatus } from "src/common/hooks/useVerificationStatus";
import { useCollectionsPermitted } from "src/components/routes/SetupPayments/common/hooks/useCollectionsPermitted";
import { useIdempotencyKeyHeader } from "src/hooks/useIdempotencyKeyHeader";

import { ExistingCustomerCreateInstructions } from "./ExistingCustomerCreateInstructions";
import { TakePaymentAsSoonAsPossibleField } from "./FormFields/TakePaymentAsSoonAsPossibleField";

interface AddToPlanForm {
  planId: string;
  mandate: string;
  takePaymentAsSoonAsPossible: "true" | "false";
  startDate: string | null;
}

interface AddToPlanProps {
  customer: CustomerResource | undefined;
}

interface AddToPlanFormComponentProps {
  planList: PlanResource[];
  mandateList: MandateResource[];
  customer: CustomerResource;
  schemeIdentifierList: SchemeIdentifier[];
}

export const AddToPlan: React.FC<AddToPlanProps> = ({ customer }) => {
  const { i18n } = useLingui();

  const organisation = useOrganisation();
  const { triggerErrorNotification } = useToastNotification();
  const { data: schemeIdentifierList } = useSchemeIdentifierList({
    organisation: organisation?.id,
  });

  const { data: mandates, isValidating: isValidatingMandateList } =
    useMandateList({
      customer: customer?.id,
      status: "active,submitted,pending_submission,pending_customer_approval",
    });
  const mandateList = mandates?.mandates || [];
  const availableCurrencies =
    mandateList?.map((mandate) =>
      _.get(schemeToCurrency, mandate.scheme as string)
    ) || [];

  const {
    data: planData,
    setSize,
    isValidating: isValidatingPlanList,
  } = useInfinitePlanList(
    {
      status: PlansStatus.Active,
      limit: 500,
    },
    true,
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
      onSuccess: (data) => {
        if (data[data?.length - 1]?.meta?.cursors?.after) {
          setSize((currentSize) => currentSize + 1);
        }
      },
      onError: () => {
        triggerErrorNotification({
          message: i18n._(
            t({
              id: "add-to-plan.plan-list.error",
              message: "There was an error, Please try again.",
            })
          ),
        });
      },
    }
  );

  const planList =
    planData
      ?.flatMap((plan) => plan?.plans || [])
      .filter((plan) => availableCurrencies.includes(plan?.currency)) || [];

  return (
    <Box>
      {!isValidatingPlanList && !isValidatingMandateList ? (
        <>
          {planList.length > 0 && mandateList.length > 0 && customer ? (
            <AddToPlanFormComponent
              planList={planList}
              mandateList={mandateList}
              customer={customer}
              schemeIdentifierList={
                schemeIdentifierList?.scheme_identifiers || []
              }
            />
          ) : (
            <NoActivePlan />
          )}
        </>
      ) : (
        <Box
          layout="flex"
          gutterV={2}
          spaceAbove={1}
          justifyContent={JustifyContent.Center}
        >
          <Icon name={Glyph.Spinner} size="40px" />
        </Box>
      )}
    </Box>
  );
};

const AddToPlanFormComponent: React.FC<AddToPlanFormComponentProps> = ({
  planList,
  mandateList,
  customer,
  schemeIdentifierList,
}) => {
  const { i18n } = useLingui();
  const { sendEventPromiseWithTimeout } = useSegment();
  const router = useRouter();
  const { triggerErrorNotification } = useToastNotification();
  const { collectionsEnabledTrackingAttribute } = useCollectionsPermitted();

  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [invalidDateChosen, setInvalidDateChosen] = useState(false);
  const idempotencyKeyHeader = useIdempotencyKeyHeader();

  const methods = useForm<AddToPlanForm>({
    defaultValues: {
      takePaymentAsSoonAsPossible: "true",
      planId: planList[0]?.id,
      mandate: mandateList[0]?.id,
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { handleSubmit, watch } = methods;

  const watchTakePaymentsAsSoonAsPossible = watch(
    "takePaymentAsSoonAsPossible"
  );
  const watchMandateId = watch("mandate");
  const watchPlanId = watch("planId");
  const watchStartDate = watch("startDate");
  const selectedPlan =
    planList.find((plan) => plan.id === watchPlanId) ?? planList[0];
  const selectedMandate =
    mandateList.find((mandate) => mandate.id === watchMandateId) ??
    mandateList[0];

  const submissionIsDisabled =
    (invalidDateChosen && watchTakePaymentsAsSoonAsPossible === "false") ||
    watchPlanId === "";

  const { isRestricted: isPlanSubscribeRestricted } = useIsRestricted({
    plans: "subscribe",
  });

  const { collectionsPermitted } = useVerificationStatus();

  const startDates =
    (selectedPlan &&
      selectedMandate &&
      availableStartDates(selectedPlan, selectedMandate)) ||
    [];
  const firstAvailableDate =
    selectedPlan && isSetPlan(selectedPlan) && startDates && startDates[0]
      ? startDates[0].value
      : castDateToString(
          selectedMandate?.next_possible_charge_date as Date | undefined
        );

  const firstAvailableCalendarDate = parseDate(firstAvailableDate);

  const [datePickerDate, setDatePickerDate] = useState<
    CalendarDate | undefined
  >(firstAvailableCalendarDate);

  const planIsSet = isSetPlan(selectedPlan as PlanResource);
  const startDate: string | null | undefined =
    watchTakePaymentsAsSoonAsPossible === "true"
      ? firstAvailableDate
      : planIsSet
        ? watchStartDate
        : datePickerDate?.toString();

  const prepareData = ({
    mandate,
  }: AddToPlanForm): PlanSubscribeRequestBody => ({
    links: {
      mandate: mandate || mandateList[0]?.id,
    },
    start_date: castStringToDate(startDate),
  });
  const [submit] = usePlanSubscribe(watchPlanId, {
    onSuccess: async (subscribeResponse) => {
      await sendEventPromiseWithTimeout(
        TrackingEvent.CUSTOMERS_ADD_TO_PLAN_COMPLETED,
        {
          subscription_id: subscribeResponse?.subscriptions?.id,
          plan_id: selectedPlan?.id,
          new_plan_created: false,
          page: router.pathname,
          customer_id: customer.id,
          ...collectionsEnabledTrackingAttribute,
        }
      );

      routerPush({
        route: Route.RecurringPaymentCreateSuccess,
        queryParams: {
          subscription_id: subscribeResponse?.subscriptions?.id,
          plan_id: selectedPlan?.id || "",
          customer_id: customer.id || "",
          created_template: "false",
        },
      });
    },
    onError: async (error) => {
      const errorPayload = await getErrorNotification(error);
      triggerErrorNotification(errorPayload.notificationPayload);
      captureException({
        error: error,
      });
    },
    headers: idempotencyKeyHeader,
  });
  const submitForm = (data: AddToPlanForm) => {
    const requestBody = prepareData(data);
    submit(requestBody);
  };

  return (
    <Box>
      {!collectionsPermitted ? (
        <>
          <Space v={2} />
          <VerifyYourAccountError
            header={
              <Trans id="customer.add-to-plan-verification-error-header">
                Unable to add customer to Subscription template
              </Trans>
            }
            subtitle={
              <Trans id="customer.add-to-plan-verification-error-title">
                Please verify your GoCardless account to add a customer to a
                Subscription template
              </Trans>
            }
          />
          <Space v={2} />
        </>
      ) : (
        <>
          <Tip
            header={i18n._(
              t({
                id: "add-to-plan.add-customer-to-existing-template-header",
                message:
                  "Add your customer to an existing Subscription template",
              })
            )}
            details={i18n._(
              t({
                id: "add-to-plan.add-customer-to-existing-template-description",
                message:
                  "Choose a Subscription template from the drop-down below and get paid hassle-free.",
              })
            )}
            showTipTag
          />
          <Space v={1.5} />
        </>
      )}
      <FormProvider {...methods}>
        <Form
          onSubmit={handleSubmit((data: AddToPlanForm) => submitForm(data))}
        >
          <PlanField
            planList={planList as PlanList}
            isRestricted={isPlanSubscribeRestricted}
          />
          <Space v={1.5} />
          <TakePaymentAsSoonAsPossibleField
            isRestricted={isPlanSubscribeRestricted}
            firstAvailableDate={firstAvailableDate}
          />
          {watchTakePaymentsAsSoonAsPossible === "false" && (
            <Box
              spaceAbove={1}
              borderRadius={1}
              gutterH={1.5}
              gutterV={1.5}
              bg={ColorPreset.BackgroundLight_03}
              borderColor={ColorPreset.BorderOnLight_03}
              borderWidth={1}
            >
              {startDates.length > 0 ? (
                <StartDatePickerField startDates={startDates} />
              ) : (
                <RestrictedDatePicker
                  id="datePickerDate"
                  onChange={setDatePickerDate}
                  defaultValue={firstAvailableCalendarDate}
                  minimumDate={firstAvailableCalendarDate}
                  setInvalidDateChosen={setInvalidDateChosen}
                />
              )}
            </Box>
          )}
          {selectedMandate &&
            selectedMandate.payments_require_approval &&
            startDate &&
            schemeIdentifierList && (
              <>
                <Space v={1.5} />
                <PaymentsRequireApprovalNotice
                  mandate={selectedMandate}
                  startDate={parseDate(startDate)}
                  schemeIdentifiers={schemeIdentifierList}
                  paymentType={PaymentType.PLAN}
                />
              </>
            )}
          {showMoreOptions && (
            <>
              <Space v={1.5} />
              <MandateField mandateList={mandateList as MandateList} />
            </>
          )}

          <Box spaceAbove={2}>
            <PlainButton
              onClick={() => setShowMoreOptions(!showMoreOptions)}
              type="button"
              disabled={isPlanSubscribeRestricted}
            >
              {showMoreOptions ? (
                <Trans id="hide-advanced-options">
                  <Text preset={TypePreset.Body_02} weight={FontWeight.Bold}>
                    Hide advanced options{" "}
                    <Icon size="11px" name={Glyph.ChevronUp} />
                  </Text>
                </Trans>
              ) : (
                <Trans id="show-advanced-options">
                  <Text preset={TypePreset.Body_02} weight={FontWeight.Bold}>
                    Show advanced options{" "}
                    <Icon size="11px" name={Glyph.ChevronDown} />
                  </Text>
                </Trans>
              )}
            </PlainButton>
          </Box>

          <MobilePreview rightPanel={<ExistingCustomerCreateInstructions />} />

          <Space v={3} />
          <Button
            disabled={submissionIsDisabled || isPlanSubscribeRestricted}
            variant={ButtonVariant.PrimaryOnLight}
            layout={[ButtonLayout.Full, null, ButtonLayout.Inline]}
            type="submit"
          >
            <Trans>Add to Subscription</Trans>
          </Button>
        </Form>
      </FormProvider>
    </Box>
  );
};

const NoActivePlan: React.FC = () => (
  <>
    <Space v={1} />
    <Banner
      leftAccessory={{
        type: BannerLeftAccessoryType.Status,
        status: BannerStatus.Warning,
      }}
      variant={BannerVariant.Light}
    >
      <P preset={TypePreset.Heading_02}>
        <Trans id="add-to-plan.no-active-subscription-template">
          No active Subscription template
        </Trans>
      </P>
      <P preset={TypePreset.Body_02}>
        <Trans id="add-to-plan.you-must-have-an-active-template">
          You must have an active Subscription template to be able to invite a
          customer to take recurring payments.
        </Trans>
      </P>
    </Banner>
    <Space v={2} />
  </>
);
